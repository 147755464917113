<template>
  <v-container>
    <v-form @submit.prevent="save">
      <v-row>
        <v-col cols="12">
          <div class="text-h4">Headlines</div>
        </v-col>
        <v-col cols="9">
          <v-text-field v-model="headline1.title" label="Headline 1"/>
        </v-col>
        <v-col cols="3">
          <v-text-field v-model="headline1.color" label="Headline 1 color"/>
        </v-col>

        <v-col cols="9">
          <v-text-field v-model="headline2.title" label="Headline 2"/>
        </v-col>
        <v-col cols="3">
          <v-text-field v-model="headline2.color" label="Headline 2 color"/>
        </v-col>

        <v-col cols="12" class="grey">
          <h4 class="text-h4">Preview</h4>
          <v-divider/>
          <div class="my-4">
            <div :style="{color:headline1.color}" class="text-h4 font-weight-bold">{{ headline1.title }}</div>
            <div :style="{color:headline2.color}" class="text-h1 font-weight-bold">{{ headline2.title }}</div>
          </div>
        </v-col>

      </v-row>
      <v-row>
        <v-col cols="12">
          <div class="text-h4">Menu Buttons</div>
        </v-col>
        <v-col cols="12" v-for="(button, index) in menuButtons" :key="index+'button'">
          <div class="text-h5">Button {{ index + 1 }}</div>
          <v-row>
            <v-col cols="12">
              <v-file-input :label="`Button ${index+1} icon`" v-model="button.localIcon" @change="uploadPhoto(button)"/>
            </v-col>
            <v-col cols="6">
              <v-text-field :label="`Button ${index + 1} name`" v-model="button.name"/>
            </v-col>
            <v-col cols="6">
              <v-text-field :label="`Button ${index + 1} link`" v-model="button.link"/>
            </v-col>
            <v-col cols="12">
              <v-textarea label="Button description" v-model="button.description"/>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4">
              <v-text-field :label="`Button ${index + 1} text color`" v-model="button.nameFontColor"/>
            </v-col>
            <v-col cols="4">
              <v-text-field :label="`Description ${index + 1} text color`" v-model="button.descriptionFontColor"/>
            </v-col>
            <v-col cols="4">
              <v-text-field :label="`Background ${index + 1} color`" v-model="button.backgroundColor"/>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" class="text-center">
          <v-btn @click="addNewButton" small outlined color="success" class="mx-1">Add Button</v-btn>
          <v-btn @click="removeButton" small outlined color="red" class="mx-1">Remove Button</v-btn>
        </v-col>
        <v-col cols="12" class="grey">
          <div class="text-h4">Preview</div>
          <v-sheet>
            <v-container>
              <v-row justify="center" align="center">
                <v-col cols="12" md="6" class="pa-1" v-for="(button, index) in menuButtons" :key="index+'buttonItem'">
                  <v-card hover outlined class="menu-card pa-2 pa-md-4" :color="button.backgroundColor">
                    <v-card-text>
                      <div class="d-flex align-center">
                        <v-img v-if="button.icon" max-width="80"
                               :src="button.icon"/>
                        <div :style="{color: button.nameFontColor}" class="text-h4 mx-2 font-weight-black">
                          {{ button.name }}
                        </div>
                      </div>
                      <div class="px-2" :style="{color: button.descriptionFontColor}">
                        {{ button.description }}
                      </div>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-container>
          </v-sheet>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <div class="text-h4">Info</div>
        </v-col>
        <v-col cols="12">
          <quill-editor
              :options="{placeholder: 'Description'}"
              v-model="specialities.text"
          ></quill-editor>
          <v-text-field v-model="specialities.video" label="Video link"/>
        </v-col>
        <v-col cols="12">
          <v-sheet>
            <v-container>
              <v-card flat>
                <div class="text-h4 font-weight-bold black--text">Why Us?</div>
                <v-row justify="center">
                  <v-col cols="12" md="6" v-if="specialities.video">
                    <div class="embed-responsive embed-responsive-16by9">
                      <iframe
                          class="embed-responsive-item"
                          :src="specialities.video"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowfullscreen
                      ></iframe>
                    </div>
                  </v-col>
                  <v-col cols="12" :md="specialities.video ? 6: 12">
                    <div class="grey--text text--darken-2" v-html="specialities.text"/>
                    <div class="text-center">
                      <v-btn color="primary" class="my-4 mx-auto" :to="{name:'CourseDetails',params:{courseId:12}}">
                        To know more
                        <v-icon class="mx-1">mdi-arrow-right-bold-circle</v-icon>
                      </v-btn>
                    </div>
                  </v-col>
                </v-row>
              </v-card>
            </v-container>
          </v-sheet>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="12" class="text-center">
          <v-btn type="submit" block color="primary">Save</v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import {quillEditor} from 'vue-quill-editor'
import axios from "axios";
import Swal from "sweetalert2";

export default {
  name: "HomeContent",
  data() {
    return {
      photo: null,
      headline1: {
        title: '',
        color: '#000000',
      },
      headline2: {
        title: '',
        color: '#000000',
      },
      menuButtons: [
        {
          name: '',
          description: '',
          nameFontColor: '',
          descriptionFontColor: '',
          backgroundColor: '',
          link: '#',
          icon: '',
          localIcon: null,
        }
      ],
      defaultMenuButton: {
        name: '',
        description: '',
        nameFontColor: '',
        descriptionFontColor: '',
        backgroundColor: '',
        icon: '',
        localIcon: null,
      },
      specialities: {
        text: '',
        photo: '',
        video: '',
      },
    }
  },
  components: {
    quillEditor
  },
  computed: {
    form() {
      return {
        headline_1: this.headline1,
        headline_2: this.headline2,
        menu_buttons: this.menuButtons,
        specialities: this.specialities,
      }
    }
  },
  created() {
    this.initialize()
  },
  methods: {
    initialize() {
      const url = 'admin/home-content'
      axios.get(url).then(response => {
        const data = response.data
        if (data) {
          data.headline_1 ? this.headline1 = data.headline_1 : ''
          data.headline_2 ? this.headline2 = data.headline_2 : ''
          data.menu_buttons ? this.menuButtons = data.menu_buttons : ''
          data.specialities ? this.specialities = data.specialities : ''
        }
      })
    },
    addNewButton() {
      this.menuButtons.push(this.defaultMenuButton)
    },
    removeButton() {
      this.menuButtons.length > 1 ? this.menuButtons.splice(this.menuButtons.length - 1, 1) : ''
    },
    uploadPhoto(button) {
      const url = 'admin/home-content'
      const formData = new FormData()
      formData.set('photo', button.localIcon)
      axios.post(url, formData).then(response => {
        button.icon = response.data
      })
    },
    save() {
      const url = 'admin/home-content'
      axios.post(url, this.form).then(() => {
        Swal.fire('Updated! ')
      })
    }
  }
}
</script>

<style scoped>

</style>